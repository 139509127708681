<template>
  <v-app>
    <v-navigation-drawer
      app
      left
      elevation="0"
      rail-width="75"
      width="280"
      mobile-breakpoint="lg"
      :mini-variant.sync="mini"
      permanent
    >
      <v-list-item class="px-2">
        <v-img :src="logo" :width="230" :height="54" />
        <v-img :src="menu" :width="32" :height="32" @click="mini = !mini" />
      </v-list-item>

      <v-divider />

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          tag
          link
        >
          <v-list-item-avatar :size="20">
            <v-img :src="item.icon" :width="20" :height="20" :rounded="true" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app :extension-height="89">
      <!-- -->
      <Header />
    </v-app-bar>

    <!-- 根据应用组件来调整你的内容 -->
    <v-main>

      <!-- 给应用提供合适的间距 -->
      <v-container fluid>

        <!-- 如果使用 vue-router -->
        <router-view />
      </v-container>
    </v-main>

    <v-footer app>
    <!-- -->
    </v-footer>
  </v-app>
</template>
<script>
import logo from '@/assets/images/marketing/logo.png'
import menu from '@/assets/images/marketing/menu.png'
import Dashboard from '@/assets/images/marketing/Dashboard.png'
import ProductCatalog from '@/assets/images/marketing/ProductCatalog.png'
import MyProducts from '@/assets/images/marketing/MyProducts.png'
import Orders from '@/assets/images/marketing/Orders.png'
import Payment from '@/assets/images/marketing/Payment.png'
import Integrations from '@/assets/images/marketing/Integrations.png'
import AffiliateProgram from '@/assets/images/marketing/AffiliateProgram2.png'
import DesignLibrary from '@/assets/images/marketing/DesignLibrary.png'
import Settings from '@/assets/images/marketing/Settings.png'
import Header from './components/Header.vue'
export default {
  components: {
    Header
  },
  data() {
    return {
      logo,
      menu,
      drawer: false,
      items: [
        { title: 'Dashboard', icon: Dashboard },
        { title: 'Product Catalog', icon: ProductCatalog },
        { title: 'MyProducts', icon: MyProducts },
        { title: 'Orders', icon: Orders },
        { title: 'Payment', icon: Payment },
        { title: 'Integrations', icon: Integrations },
        { title: 'Affiliate Program', icon: AffiliateProgram },
        { title: 'DesignLibrary', icon: DesignLibrary },
        { title: 'Settings', icon: Settings }
      ],
      mini: false
    }
  }
}
</script>
