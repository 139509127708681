<template>
  <v-app-bar elevation="0">
    <v-row>
      <v-col cols="12" lg="5">
        <v-text-field
          solo
          label="Search"
          :hide-details="true"
          :width="364"
          :loader-height="28"
          :full-width="false"
        >
          <template v-slot:prepend-inner>
            <v-img :src="sousuo" :width="16" :height="16" />
          </template>
          <template v-slot:append-inner>
            <v-img :src="heng" :width="32" :height="32" />
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-spacer />
    <v-menu :close-on-content-click="false">
      <template v-slot:activator="{ props }">
        <v-avatar :width="36" :height="36" class="ml-5">
          <img
            src="https://cdn.vuetifyjs.com/images/john.jpg"
            alt="John"
          >
        </v-avatar>
        <div class="ml-5" style="width: 36px">
          <v-img :src="gouWuChe" :width="36" />
        </div>
        <v-btn
          rounded
          class="text-secondary hidden-sm-and-down"
          color="lightsecondary"
          size="small"
          variant="flat"
          v-bind="props"
        >
          <v-img :src="shopify" :width="30" :height="30" class="mr-2" />
          My Store
          <v-img :src="down" :width="12" :height="7.4" class="ml-2" />
          <!-- <AccessPointIcon stroke-width="1.5" size="22" /> -->
        </v-btn>
      </template>
      <v-sheet width="900" height="395" elevation="12" class="pa-4">
        <v-row>
          <v-col cols="12" lg="4">
            <!-- <img src="@/assets/images/background/mega-bg.svg"> -->
          </v-col>
          <v-col cols="12" lg="8">
            <v-list density="compact" class="overflow-hidden pt-6">
              <v-row no-gutters>
                <div v-for="(item, i) in dropdownMenu" :key="i">
                  <v-row cols="6" sm="4">
                    <v-list-item v-if="item.header">
                      <v-list-item-title class="text-subtitle-1">{{ item.header }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="item.link"
                      :href="item.href"
                      color="secondary"
                      target="_blank"
                      class="no-spacer"
                    >
                      <template v-slot:prepend>
                        <v-icon class="text-8 mr-2">mdi-circle</v-icon>
                      </template>
                      <v-list-item-title class="text-subtitle-1 font-weight-regular">{{ item.link }} </v-list-item-title>
                    </v-list-item>
                  </v-row>
                </div>
              </v-row>
            </v-list>
          </v-col>
        </v-row>
      </v-sheet>
    </v-menu>
  </v-app-bar>
</template>
<script>
import sousuo from '@/assets/images/marketing/search.png'
import heng from '@/assets/images/marketing/heng.png'
import shopify from '@/assets/images/marketing/shopify.png'
import down from '@/assets/images/marketing/down.png'
import gouWuChe from '@/assets/images/marketing/gouWuChe.png'
export default {
  data() {
    return {
      sousuo,
      heng,
      shopify,
      down,
      gouWuChe,
      dropdownMenu: [
        { header: 'User Quick' },
        { header: 'Applications' },
        { header: 'Primitives' },
        {
          link: 'Social Profile',
          href: 'app/user/social/posts'
        },
        {
          link: 'Chat',
          href: 'app/chats'
        },
        {
          link: 'Colors',
          href: 'utils/colors'
        }]
    }
  }
}
</script>
